// Variables declaration

//FONTS
$fontTitle: 'Amatic SC', cursive;
$fontText: 'Roboto', sans-serif;
$fontOther: 'Poppins', sans-serif;

//IMAGES
$imgPath: '../../Assets/img/';
// Use : background-image: url('#{$imgPath}menu-wave-mini.svg');

//COLORS
$black: #000000;
$dark: #222222;
$white: #ffffff;
$light: #fffaf7;
$light-2: #fdf3ed;
$light-3: #fad8c3;
$pale: #f4e8e1;
$grey-medium: #8e8e8e;
$grey: #afafaf;
$grey-light: #e3e3e3;
$very-light-grey: #cbcbcb;
$grey-lighter:#f2f2f2;
$very-light-pink: #C9C9C9;
$orange-lighter: #EDCFBE;
$orange-light: #eb9118;
$orange: #eb651c;
$orange-dark: #d84c00;
$salmon: #ffbfa5;
$pink: #f75969;
$red: #e43528;
$red-2: #e8412c;
$green: #80a51b;
$sap-green: #658313;
$green-dark: #35450a;
$blue-green-light: #7dc3c0;
$soft-blue: #63b9e9;
$blue: #009cb4;
$blue-green: #2b817d;
$blue-green-dark: #133836;
$blue-navy: #1a3e70;
$blue-grey: #6e8a99;
$brown-chocolate: #893a1f;
$brown: #928161;
$brown-dark: #70624f;
$brown-light: #c1b49c;
$brown-grey:#989898;
$beige: #d6ccb9;


$colors: (
        "black": (
                "bgDefault":$black,
                "bgHover":$dark,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $dark
        ),
        "white":(
                "bgDefault":$white,
                "bgHover":transparent,
                "colorDefault":$dark,
                "colorHover": $white,
                "gradient": $light
        ),
        "light":(
                "bgDefault":$light,
                "bgHover":$light-3,
                "colorDefault":$dark,
                "colorHover": $dark,
                "gradient": $white
        ),
        "light-2":(
                "bgDefault":$light-2,
                "bgHover":transparent,
                "colorDefault":$dark,
                "colorHover": $dark,
                "gradient": $white
        ),
        "light-3":(
                "bgDefault":$light-3,
                "bgHover":$orange-light,
                "colorDefault":$dark,
                "colorHover": $white,
                "gradient": $light
        ),
        "orange-light":(
                "bgDefault":$orange-light,
                "bgHover":$orange,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $light-3
        ),
        "orange":(
                "bgDefault":$orange,
                "bgHover":$orange-dark,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $orange-light
        ),
        "orange-dark":(
                "bgDefault":$orange-dark,
                "bgHover":$orange,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $orange
        ),
        "dark":(
                "bgDefault":$dark,
                "bgHover":$blue-grey,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $grey
        ),
        "green":(
                "bgDefault":$green,
                "bgHover":$sap-green,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $light-3
        ),
        "green-dark":(
                "bgDefault":$green-dark,
                "bgHover":$green,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $green
        ),
        "soft-blue":(
                "bgDefault":$soft-blue,
                "bgHover":$blue,
                "colorDefault":$white,
                "colorHover":$white,
                "gradient": $blue-green-light
        ),
        "blue-cyan":(
                "bgDefault":$blue,
                "bgHover":$blue-navy,
                "colorDefault":$white,
                "colorHover":$white,
                "gradient": $soft-blue
        ),
        "blue-navy":(
                "bgDefault":$blue-navy,
                "bgHover":$soft-blue,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $blue
        ),
        "blue-grey":(
                "bgDefault":$blue-grey,
                "bgHover":$dark,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $blue-green-light
        ),
        "blue":(
                "bgDefault":$blue,
                "bgHover":$blue-green-dark,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $blue-green-light
        ),
        "blue-green":(
                "bgDefault":$blue-green,
                "bgHover":$blue-green-dark,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $blue-green-light
        ),
        "blue-green-dark":(
                "bgDefault":$blue-green-dark,
                "bgHover":$blue-green,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $blue-green
        ),
        "brown-chocolate":(
                "bgDefault":$brown-chocolate,
                "bgHover":$brown,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $beige
        ),
        "brown":(
                "bgDefault":$brown,
                "bgHover":$brown-dark,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $brown-light
        ),
        "brown-dark":(
                "bgDefault":$brown-dark,
                "bgHover":$brown,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $brown
        ),
        "pink":(
                "bgDefault":$pink,
                "bgHover":$red-2,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $salmon
        ),
        "red-2":(
                "bgDefault":$red-2,
                "bgHover":$pink,
                "colorDefault":$white,
                "colorHover": $white,
                "gradient": $beige
        )
);

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@function toRGBA ($color, $opacity) {
    @return rgba(red($color), green($color), blue($color), $opacity);
}