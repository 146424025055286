@import "../utils/_helpers.scss";

.loader {
    margin: 100px auto;
    img {
        display: block;
        width: 60px;
        height: auto;
    }
    &:not(.button--) {
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.button-- {
        position: relative;
        margin: 0 auto;
        min-width: 60px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.loading-page {
    background-color: $white;
    .loading_headImg {
        height: 400px;
        width: 100%;
        background-image: url('#{$imgPath}bar.gif');
        background-position: top left;
        background-repeat: repeat;
        background-size: 100% 100%;
        @include m768 {
            height: 380px;
        }
    }
    .layout {
        width: 100%;
    }
    .loading_inner {
        padding-top: 50px;
    }
    .loading {
        width: 100%;
        background-image: url('#{$imgPath}bar.gif');
        background-position: top left;
        background-repeat: repeat;
        background-size: 100% 100%;
        .shape {
            background-color: $white;
        }
        .blank_title {
            height: 30px;
            @include m768 {
                height: 52px;
            }
            @include m1024 {
                height: 67px;
            }
        }
        .shape_title {
            height: 43px;
        }
        .blank_intro {
            height: 88px;
            @include m768 {
                height: 108px;
            }
            @include m1024 {
                height: 127px;
            }
        }
        .shape_intro {
            height: 60px;
            @include m768 {
                height: 70px;
            }
            @include m1024 {
                height: 80px;
            }
        }
        .blank_fat {
            height: 165px;
        }
        .shape_fat {
            height: 40px;
        }
        .blank_text {
            height: 300px;
        }
        .shape_text {
            height: 80px;
        }
    }
}